/**
 * Promise of tuff work here...
 */
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);
.dicf {
  --bg-color-gray-6: #FAFBFC;
  --bg_color_white: #FFF;
  --border-secondary-color: #EBF2FA;
  --border-radius-small: 4px;
  font-family: 'Roboto', sans-serif;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
  background-color: var(--bg-color-gray-6);
}
.dicf .dashboard-component-tabs,
.dicf #app .ant-tabs-content-holder {
  background-color: transparent;
}
.dicf #app .dashboard-component-chart-holder {
  background-color: var(--bg_color_white);
  border: 1px solid var(--border-secondary-color);
  border-radius: var(--border-radius-small);
}
.dicf .filter-bar-container {
  display: none;
}
.dicf .filter-container label {
  font-weight: normal;
}
.dicf .dashboard {
  color: #1e1e1e;
}
.dicf .dashboard .chart-header {
  font-weight: 500;
}
.dicf .table > thead > tr > th {
  font-size: 0.75rem;
}
.dicf #app .ant-tabs-nav {
  margin-bottom: 1em;
}
.dicf.standalone .dashboard .grid-container {
  margin: 0;
}
.dicf.standalone .dashboard .chart-slice .header-controls > *:first-child {
  display: none;
}
.wcca {
  --bg-color-gray-6: #FAFBFC;
  --bg_color_white: #FFF;
  --border-secondary-color: #EBF2FA;
  --border-radius-small: 4px;
  font-family: 'Roboto', sans-serif;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
  background-color: var(--bg-color-gray-6);
}
.wcca .dashboard-component-tabs,
.wcca #app .ant-tabs-content-holder {
  background-color: transparent;
}
.wcca #app .dashboard-component-chart-holder {
  background-color: var(--bg_color_white);
  border: 1px solid var(--border-secondary-color);
  border-radius: var(--border-radius-small);
}
.wcca .filter-bar-container {
  display: none;
}
.wcca .filter-container label {
  font-weight: normal;
}
.wcca .dashboard {
  color: #1e1e1e;
}
.wcca .dashboard .chart-header {
  font-weight: 500;
}
.wcca .table > thead > tr > th {
  font-size: 0.75rem;
}
.wcca #app .ant-tabs-nav {
  margin-bottom: 1em;
}
.wcca.standalone .dashboard .grid-container {
  margin: 0;
}
.wcca.standalone .dashboard .chart-slice .header-controls > *:first-child {
  display: none;
}
